<template>
  <b-row>
    <b-col
      lg="3"
      md="4"
      sm="6"
      cols="6"
      v-for="(product, index) in relatedProducts"
      :key="index"
      class="panino"
    >
      <div class="container" @click="info(product.name, product.image)">
        <div class="mx-auto img">
          <b-img :src="product.image" fluid />
        </div>
        <div class="item-heading">
          <h5 class="mb-0" style="text-align: center">
            {{ product.name }}
          </h5>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      relatedProducts: [
        {
          name: "Birra Beck's",
          image: require("@/assets/images/menu/bibite/becks.png"),
        },
        {
          name: "Coca Cola",
          image: require("@/assets/images/menu/bibite/coca.png"),
        },
      ],
    };
  },
  methods: {
    info(nome, image) {
      this.$swal({
        title: nome,
        text: "Disponibile in lattina e in bottiglia da 0.5l",
        imageUrl: image,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Custom image",
        showCloseButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
  },
};
</script>

<style scoped>
.panino {
  padding: 20px;
  transition: all 0.6s;
}

@media (min-width: 991px) {
  .panino:hover {
    padding: 0px;
  }
}
.container {
  cursor: pointer;
}
.img {
  max-width: 150px;
}
</style>