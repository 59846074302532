<template>
  <b-row>
    <b-col
      lg="3"
      md="4"
      sm="6"
      cols="6"
      v-for="(product, index) in relatedProducts"
      :key="index"
      class="panino"
    >
      <div
        class="container"
        @click="
          info(product.name, product.image, product.ingredienti, product.prezzo)
        "
      >
        <div class="mx-auto img">
          <b-img :src="product.image" fluid />
        </div>
        <div class="item-heading">
          <h5 class="mb-0" style="text-align: center">
            {{ product.name }}
          </h5>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  data() {
    return {
      relatedProducts: [
        {
          name: "Il Liscio",
          image: require("@/assets/images/menu/panini/Liscio.jpg"),
          ingredienti: "Salsiccia d’Muncalè o Würstel e una salsa a scelta",
          prezzo: 4.5,
        },
        {
          name: "Il Pigiamino",
          image: require("@/assets/images/menu/panini/Pigiamino.jpg"),
          ingredienti: "Salsiccia d’Muncalè, Formaggio e cipolla caramellata",
          prezzo: 5.5,
        },
        // {
        //   name: "Il Nutrizionista",
        //   image: require("@/assets/images/menu/panini/Pigiamino.jpg"),
        //   ingredienti:
        //     "Due fette di petto di pollo, Formaggio Light, verdure grigliate, Ketchup Zero, Maionese Light",
        //   prezzo: 6.5,
        // },
        // {
        //   name: "Il Fortunello",
        //   image: require("@/assets/images/menu/panini/Pigiamino.jpg"),
        //   ingredienti:
        //     "Salsiccia d’Muncalè, Formaggio, Cipolla caramellata, Peperoni di Carmagnola, ketchup, maionese(entrambi Heinz) e Nduja di Spilinga",
        //   prezzo: 6.9,
        // },
        {
          name: "Lo Spinagino",
          image: require("@/assets/images/menu/panini/Spinagino.jpg"),
          ingredienti:
            "Salsiccia d’Muncalè, 4 fette di Scamorza affumicata calabra, foglie di spinaci e maionese",
          prezzo: 6.9,
        },
        {
          name: "La Bestia",
          image: require("@/assets/images/menu/panini/Bestia.jpg"),
          ingredienti:
            "Salsiccia d’Muncalè, 4 fette di scamorza affumicata calabra, 4 fette di Porchetta, Ketchup e Maionese (entrambi Heinz)",
          prezzo: 8.5,
        },
        {
          name: "Lo Sgarro (1/2 KG)",
          image: require("@/assets/images/menu/panini/Sgarro.jpg"),
          ingredienti:
            "Doppia salsiccia d’Muncalè (240g), 8 fette di scamorza affumicata (130g), 4 fette di porchetta(130g), Ketchup, Maio e piccante",
          prezzo: 12.7,
        },
        // {
        //   name: "Il Vegetariano",
        //   image: require("@/assets/images/menu/panini/Sgarro.jpg"),
        //   ingredienti:
        //     "Salsiccia Plant-Based, Formaggio Vegano e verdure grigliate",
        //   prezzo: 7.9,
        // },
      ],
    };
  },
  methods: {
    info(nome, image, ing, price) {
      this.$swal({
        title: nome + " " + price + "0€",
        text: ing,
        imageUrl: image,
        imageWidth: 350,
        imageAlt: "Custom image",
        showCloseButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
  },
};
</script>

<style scoped>
.panino {
  padding: 20px;
  transition: all 0.6s;
}

@media (min-width: 991px) {
  .panino:hover {
    padding: 0px;
  }
}

.container {
  cursor: pointer;
}

.img {
  max-width: 250px;
}
</style>